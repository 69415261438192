//
// style short teaser
//

$badge-size: 6rem;

.ShortTeaser {
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	position: relative;
	border: 10px solid #5a5a5a;
	height: 400px;
	padding: 1rem;
	background: transparent;
	transition: .3s border-color cubic-bezier(.455, .03, .515, .955);
	color: #fff;
	cursor: pointer;
	overflow: hidden;

	div[data-alphabetgroup] & {
		height: 80vw;
		@media print, screen and (min-width: 40em) {
			height: 40vw;
		}
	
		@media print, screen and (min-width: 64em) {
			height: 400px;
		}
	}

	&:hover {
		border-color: #a6a6a6;
		text-decoration: none;
		.title {
			text-decoration: underline;
		}

		//.badge {
		//	background-color: white;
		//}
	}
}

.badge {
	position: absolute;
	width: 200px;
	height: 200px;
	transform: rotate(45deg);

	top: 0;
	right: 0;
	z-index: 10;
}

.badgeLabel {
	position: absolute;
	top: 0;
	right: 0;
	width: 200px;
	font-size: .8rem;
	font-weight: bold;
	text-align: center;
	padding: .5rem 0;
	background-color: #df1051;
	color: #fff;
}

.title {
	position: relative;
	margin: 1rem 0 1rem;
	font-size: 1.675rem;
    line-height: 1.1;
    font-weight: 600;
	white-space: pre-line;
	hyphens: auto;
	color: #fff;
	border-bottom: 1px solid transparent;
}

.subtitle {
	position: relative;
	margin: 0 0 1rem;
	font-size: 1rem;
	font-weight: 300;
    line-height: 1.1;
	white-space: pre-line;
	hyphens: auto;
	color: #fff;
}

.bgImage {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: 0;
}

.bgColor {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 0;
	transition: .3s opacity cubic-bezier(.455, .03, .515, .955);
}

.topImage {
	position: absolute;
	top: 5vw;
	max-width: 50%;
	max-height: 40px;

	@media print, screen and (min-width: 40em) {
		
	}

	@media print, screen and (min-width: 64em) {
		top: 2vw;
	}

	.center & {	
		left: 50%;
		transform: translateX(-50%);
	}
}

.bottomTags {
	position: relative;
	display: flex;
	margin: 1rem 0 0 0;
	color: #fff;
	font-size: .875rem;

	li {
		display: inline-flex;
		margin: 0 1rem 0 0;
		align-content: center;

		svg {
			align-self: center;
			margin: 0 .25rem 0 0;
		}

		&:last-of-type {
			margin: 0;
		}
	}
}

// Layout variants
/*! purgecss start ignore */
.layout-default {

	.title,
	.bottomIcons{
		color: #fff;
	}
}

.layout-imageOnHover {
	.bgImage {
		opacity: 0;
		transition: .5s opacity cubic-bezier(.455, .03, .515, .955);
	}

	.bgColor {
		display: none;
	}

	&:hover {
		border-color: #fff;
		.bgImage {
			opacity: 1;
		}
	}
}

.layout-colorOnHover,
.layout-colorOnHoverNoBgImage {

	overflow: visible;

	.bgImage {
		opacity: 1;
	}

	.bgColor {
		opacity: 0;
		top: -10px;
		left: -10px;
		right: -10px;
		bottom: -10px;
	}

	.ShortTeaser {

		&:hover {
			border-color: #5a5a5a;
			.bgImage {
				opacity: 0;
			}
	
			.bgColor {
				opacity: 1;
			}
		}
	}
	
}

.layout-colorOnHoverNoBgImage {

	overflow: visible;

	.title,
	.bottomIcons{
		color: #000;
	}

	&:hover {
		.title,
		.bottomIcons{
			color: #fff;
		}
	}

	//.bgImage {
	//	display: none;
	//}
	//
	//.bgColor {
	//	opacity: 1;
	//	top: -15px;
	//	left: -15px;
	//	right: -15px;
	//	bottom: -15px;
	//}
}

.layout-imageAlways {


}
/*! purgecss end ignore */
