//
// style short teaser
//
$border-width: 10px;
$teaser-height: 600px;

.ExtendedTeaser {
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	position: relative;
	border: $border-width solid #737373;
	height: $teaser-height;
	padding: 5vw;
	background: transparent;
	transition: .3s border-color cubic-bezier(.455, .03, .515, .955);
	color: #fff;
	box-sizing: border-box;
	cursor: pointer;

	@media print, screen and (min-width: 64em) {
		padding: 2vw;
	}

	&:hover {
		border-color: #a6a6a6;
		.bgImage {
			filter: brightness(90%) saturate(100%);
		}
	}

	&.center {
		justify-content: center;
		text-align: center;
		
		a {
			align-self: center;
		}
	}

	a {
		align-self: flex-start;
	}
}

.title {
	position: relative;
	margin: 1rem 0 1rem;
	font-size: 2rem;
    line-height: 1.1;
    font-weight: 600;
	white-space: pre-line;
	hyphens: auto;
	color: #fff;
}

.subtitle {
	position: relative;
	margin: 0 0 1rem;
	font-size: .825rem;
	font-weight: 300;
    line-height: 1.1;
	white-space: pre-line;
	hyphens: auto;
	color: #fff;
}

.text {
	position: relative;
	margin: 0 0 1rem;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.6;
	white-space: pre-line;
}

.bgImage {
	object-fit: cover;
	filter: brightness(90%) saturate(30%);
	z-index: 0;
	transition: .3s filter cubic-bezier(.455, .03, .515, .955);
}

.bgColor {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 0;
	transition: .5s opacity cubic-bezier(.455, .03, .515, .955);
}

.topImage {
	position: absolute;
	top: 5vw;

	@media print, screen and (min-width: 40em) {
		
	}

	@media print, screen and (min-width: 64em) {
		top: 2vw;
	}

	.center & {	
		left: 50%;
		transform: translateX(-50%);
	}
}

/*! purgecss start ignore */
.topImage--rect-small {
	max-width: 50%;
	max-height: 50px;
}

.topImage--rect-almost-quad {
	max-width: 50%;
	max-height: 80px;
}

.topImage--rect {
	max-width: 50%;
	max-height: 60px;
}

.topImage--quad {
	max-width: 50%;
	max-height: 80px;
}
/*! purgecss end ignore */

.bottomTags {
	position: relative;
	display: flex;
	margin: 1rem 0 0 0;
	color: #fff;
	font-size: .875rem;

	li {
		display: inline-flex;
		margin: 0 1rem 0 0;
		align-content: center;

		svg {
			align-self: center;
			margin: 0 .25rem 0 0;
		}

		&:last-of-type {
			margin: 0;
		}
	}
}

// Layout variants
.layout-default {

	.title,
	.bottomIcons{
		color: #fff;
	}
}

.layout-imageOnHover {
	.bgImage {
		opacity: 0;
		transition: .5s opacity cubic-bezier(.455, .03, .515, .955);
	}

	.bgColor {
		display: none;
	}

	&:hover {
		border-color: #fff;
		.bgImage {
			opacity: 1;
		}
	}
}

.layout-colorOnHover,
.layout-colorOnHoverNoBgImage {

	overflow: visible;

	.bgImage {
		opacity: 0;
	}

	.bgColor {
		opacity: 1;
		top: -$border-width;
		left: -$border-width;
		right: -$border-width;
		bottom: -$border-width;
	}

	&:hover {
		border-color: #fff;

		.bgImage {
			opacity: 1;
		}

		.bgColor {
			opacity: 1;
		}
	}
}

.layout-colorOnHoverNoBgImage {

	overflow: visible;

	.title,
	.bottomIcons{
		color: #000;
	}

	&:hover {
		.title,
		.bottomIcons{
			color: #fff;
		}
	}
}

.textBackgroundColor--black {
	background-color: rgba(0,0,0,0.5);
	position: relative;
	padding: 0 20px 20px 20px;
	width: fit-content;
}